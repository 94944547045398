import React, { Suspense } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
const UnderConstraction = React.lazy(() => import('./components/UnderConstraction'));

const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'dark' && {
            background: {
                default: grey[900],
                paper: grey[900]
            },
            primary: {
                main: '#f5df4d'
            }
        })
    }
});

const darkModeTheme = createTheme(getDesignTokens('dark'));

const useStyles = makeStyles({
    container: {
        display: 'flex',
        minHeight: '100vh',
        alignItems: 'center'
    }
});

function App() {
    const classes = useStyles();
    return (
        <ThemeProvider theme={darkModeTheme}>
            <div className={classes.container}>
                <CssBaseline />
                <Container>
                    <Suspense fallback={<div></div>}>
                        <UnderConstraction theme={darkModeTheme} />
                    </Suspense>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;
